import { z } from 'zod'

export const organizationAbilitiesSchema = z.object({
  // Partner abilities
  createVerifiedDomain: z.boolean().default(false),
  // Awareness Training Module
  enableTrainingAwareness: z.boolean().default(false),
  // CTI Module
  enableCyberThreadIntelligence: z.boolean().default(false),
  viewCyberThreadIntelligenceFeatureWall: z.boolean().default(false),
  // Vishing Module
  viewVishingFeatureWall: z.boolean().default(true),
  enableVishing: z.boolean().default(false),
})

export type OrganizationAbilities = z.infer<typeof organizationAbilitiesSchema>
