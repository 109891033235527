import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const vishingListCampaignsRoute: RouteRecordRaw = {
  path: '/vishing/campaigns',
  name: 'vishing-list-campaigns',
  component: () => import('./ListCampaigns.vue'),
  meta: {
    action: Action.ACCESS,
    subject: Subject.VISHING,
  },
}
