import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const vishingFeatureWallRoute: RouteRecordRaw = {
  path: '/vishing/overview',
  name: 'vishing-feature-wall',
  component: () => import('./VishingFeatureWall.vue'),
  meta: {
    action: Action.ACCESS,
    subject: Subject.VISHING_FEATURE_WALL,
  },
}
