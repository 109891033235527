import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

// ------------------------------------------------

export const featureWallRoute: RouteRecordRaw = {
  path: '/breaches/overview',
  name: 'breaches-feature-wall',
  component: () => import('./FeatureWallPage.vue'),
  meta: {
    action: Action.VIEW,
    subject: Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL,
  },
}

// ------------------------------------------------
