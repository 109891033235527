import type { RouteRecordRaw } from 'vue-router'
import { featureWallRoute } from '@/modules/CyberThreatIntelligence/app/views/Breaches/FeatureWall/routes'
import { monitoredDomainRepository } from '@/modules/CyberThreatIntelligence/infra/adapters/monitored-domain.repository'

// ------------------------------------------------

export const listBreachesRoute: RouteRecordRaw = {
  component: () => import('./ListBreachesPage.vue'),
  name: 'breaches-list',
  path: '/breaches/:domain',
  props: true,
}

// ------------------------------------------------

// async function redirectToModuleOrFeatureWall(to: any, _from: any, next: any) {
//   const {
//     domains: [domain],
//   } = await monitoredDomainRepository.findAll()

//   if (!domain) {
//     return next({ name: featureWallRoute.name })
//   }

//   if (!to.params.domain) {
//     return next({ path: `/breaches/${domain.name}` })
//   }

//   return next()
// }

// ------------------------------------------------
