import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'
import { trainingAwarenessFeatureWallRoute } from './views/FeatureWall/routes'

export const trainingAwarenessRoutes: RouteRecordRaw[] = [
  trainingAwarenessFeatureWallRoute,
  {
    name: 'training-awareness-list-programs',
    path: '/training/programs',
    component: () => import('./views/ListPrograms/ListProgramsPage.vue'),
    meta: {
      action: Action.ACCESS,
      subject: Subject.TRAINING_AWARENESS,
    },
  },
  {
    name: 'training-awareness-create-program',
    path: '/training/programs/new',
    component: () => import('./views/CreateProgram/CreateProgramPage.vue'),
    meta: {
      action: Action.ACCESS,
      subject: Subject.TRAINING_AWARENESS,
    },
  },
  {
    name: 'training-awareness-edit-program',
    path: '/training/programs/:programId/edit',
    component: () => import('./views/EditProgram/EditProgramPage.vue'),
    meta: {
      action: Action.ACCESS,
      subject: Subject.TRAINING_AWARENESS,
    },
  },
  {
    name: 'training-awareness-library',
    path: '/training/library',
    component: () => import('./views/Library/LibraryPage.vue'),
    meta: {
      action: Action.ACCESS,
      subject: Subject.TRAINING_AWARENESS,
    },
  },
]
