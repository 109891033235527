import { fetchOrganization } from '@/api/organization'
import { useAbility } from '@/plugins/casl'
import { Domain } from '@/types/domain'
import { Organization } from '@/types/organization'
import {
  OrganizationSubscriptionTrial,
  SubscriptionPlan,
  type OrganizationSubscriptionEnterprise,
} from '@/types/organization/subscription'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'

export const useOrganizationStore = defineStore('organization', {
  state: () => ({
    data: undefined as Organization | undefined,
    isLoading: false,
  }),
  getters: {
    rootOrg: (state) => state.data as Organization,

    domains(): Domain[] {
      return this.rootOrg.organization.domains ?? []
    },

    hasVerifiedDomain: (state) => {
      return state.data?.organization.domains.some((domain) => domain.isVerified === true) ?? false
    },

    organization: (state) => state.data?.organization,

    isTrainingAwarenessModuleEnabled: (state) =>
      state.data?.organization.modules.includes('TRAINING_AWARENESS') ?? false,

    isCyberThreatIntelligenceModuleEnabled: (state) =>
      state.data?.organization.modules.includes('CYBER_THREAT_INTELLIGENCE') ?? false,

    isVishingModuleEnabled: (state) =>
      state.data?.organization.modules.includes('VISHING') ?? false,

    verifiedDomains(state): Domain[] {
      return state.data?.organization.domains.filter((domain) => domain.isVerified === true) ?? []
    },

    verifiedDomainNames(state): Domain['name'][] {
      return (
        state.data?.organization.domains
          .filter((domain) => domain.isVerified === true)
          .map((domain) => domain.name) ?? []
      )
    },

    belongsToPartner(state) {
      if (!this.isEnterprise) return false

      const subscription = state.data?.organization
        .subscription as OrganizationSubscriptionEnterprise

      return subscription.belongsToPartnerId !== null
    },

    isTrial: (state) => state.data?.organization.subscription.plan === SubscriptionPlan.TRIAL,

    isEnterprise: (state) =>
      state.data?.organization.subscription.plan === SubscriptionPlan.ENTERPRISE,

    isOnDemand: (state) =>
      state.data?.organization.subscription.plan === SubscriptionPlan.ON_DEMAND,

    isTrialExpired(state): boolean {
      if (!this.isTrial) return false

      const { expiresAt } = state.data?.organization.subscription as OrganizationSubscriptionTrial

      return DateTime.fromISO(expiresAt).toJSDate() <= new Date()
    },

    isTrialEmpty(state): boolean {
      if (!this.isTrial) {
        return false
      }

      const remaining = (state.data?.organization.subscription as OrganizationSubscriptionTrial)
        .phishingQuota.remaining

      return remaining === 0
    },

    isEmailProviderMicrosoft(state): boolean {
      return state.data?.organization.preferences.email.provider === 'MICROSOFT_EXCHANGE_ONLINE'
    },

    isWorkOsIdentityDirectoryGranted: (state) => state.data?.organization.workOs.isGranted === true,
  },
  actions: {
    setOrganization(data: Organization) {
      this.data = data
    },

    async setOrganizationFromApi() {
      try {
        this.isLoading = true

        const org = await fetchOrganization()

        this.setOrganization(org)

        console.log('Start updating abilities...')

        const { update } = useAbility()

        update()
        console.log('Finish updating abilities...')
      } catch (error: any) {
        throw new Error('Error while fetching organization', error)
      } finally {
        this.isLoading = false
      }
    },
  },
})
