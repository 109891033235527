<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useAppStore } from './stores/app'

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme } =
  useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

const appStore = useAppStore()
</script>

<template>
  <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
  <v-app
    :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`"
    class="w-100"
  >
    <div v-if="appStore.displayLoader" class="d-flex justify-center align-center h-100">
      <img id="app-loader" src="/loader.svg" alt="Logo" data-testid="loader" class="loader" />
    </div>
    <template v-else>
      <router-view />
      <ClientUpdate />
    </template>
  </v-app>
</template>
