import { RouteRecordRaw } from 'vue-router'
import { vishingFeatureWallRoute } from './views/FeatureWall/route'
import { vishingListCampaignsRoute } from './views/ListCampaigns/route'
import { vishingListScenariosRoute } from './views/ListScenarios/route'

export const vishingRoutes: RouteRecordRaw[] = [
  vishingFeatureWallRoute,
  vishingListCampaignsRoute,
  vishingListScenariosRoute,
]
