import { can } from '@/@layouts/plugins/casl'
import { Action, Subject } from '@/types/acl'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { featureWallRoute } from './FeatureWall/routes'
import { typosquattingRoute } from './Typosquatting/routes'
import { takedownsRoute } from './Takedowns/routes'

// ------------------------------------------------

export const domainMonitoringRoute: RouteRecordRaw = {
  beforeEnter: redirectToModuleOrFeatureWall,
  name: 'domain-monitoring',
  path: '/domain-monitoring',
  children: [featureWallRoute, takedownsRoute, typosquattingRoute],
}

// ------------------------------------------------

async function redirectToModuleOrFeatureWall(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: any,
) {
  const isCyberThreatIntelligenceEnabled = can(Action.READ, Subject.CYBER_THREAT_INTELLIGENCE)
  const shouldNavigateToFeatureWall =
    !isCyberThreatIntelligenceEnabled &&
    can(Action.VIEW, Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL)

  if (shouldNavigateToFeatureWall && to.name !== featureWallRoute.name) {
    return next({ name: featureWallRoute.name })
  }

  return next()
}

// ------------------------------------------------
