import { canNavigate } from '@/@layouts/plugins/casl'
import { useMyselfStore } from '@/stores/myself'
import { isUserLoggedIn } from '@/utils/auth'
import { NavigationGuard } from 'vue-router'
import { useOrganizationStore } from '@/stores/organization'

export const authGuard: NavigationGuard = (to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  const { isLoggedInAsPartner, isLoggedInAsOrganization } = useMyselfStore()

  const orgStore = useOrganizationStore()

  console.log('to', to)
  console.log(`[router:beforeEach] Going to named route "${String(to.name)}" (${to.path})`, {
    isLoggedIn,
    isLoggedInAsPartner,
    isLoggedInAsOrganization,
    caslResource: to?.meta?.subject ?? 'none',
    caslAction: to?.meta?.action ?? 'none',
    organizationFromStore: orgStore.organization,
  })

  if (!canNavigate(to)) {
    console.log(`[router#beforeEach] [${to.path}] Cannot navigate to path '${String(to.name)}'...`)
    // Redirect to login if not logged in

    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (isLoggedInAsPartner) return next({ name: 'partner-dashboard' })

    // else... redirect to 404
    return next({ name: '404' })
  }

  if (isLoggedIn && to.meta.redirectIfLoggedIn) {
    console.log(
      `[router:beforeEach] [${to.path}] Force redirection to dashboard since user is already logged-in...`,
    )

    return next({ name: 'dashboard' })
  }

  return next()
}
