import { monitoredDomainRepository } from '@/modules/CyberThreatIntelligence/infra/adapters/monitored-domain.repository'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { featureWallRoute } from '../FeatureWall/routes'
import { can } from '@/@layouts/plugins/casl'
import { Action, Subject } from '@/types/acl'

// ------------------------------------------------

export const typosquattingRoute: RouteRecordRaw = {
  beforeEnter: redirectToModuleOrFeatureWall,
  component: () => import('./TyposquattingPage.vue'),
  name: 'domain-monitoring-typosquatting',
  path: '/domain-monitoring/typosquatting/:domain?',
  props: true,
}

// ------------------------------------------------

async function redirectToModuleOrFeatureWall(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const isCyberThreatIntelligenceEnabled = can(Action.READ, Subject.CYBER_THREAT_INTELLIGENCE)
  const shouldNavigateToFeatureWall =
    !isCyberThreatIntelligenceEnabled &&
    can(Action.VIEW, Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL)

  if (shouldNavigateToFeatureWall && to.name !== featureWallRoute.name) {
    return next({ name: featureWallRoute.name })
  }

  const {
    domains: [domain],
  } = await monitoredDomainRepository.findAll()

  if (!domain) {
    return next({ name: featureWallRoute.name })
  }

  if (!to.params.domain) {
    return next({ path: `/domain-monitoring/typosquatting/${domain.name}` })
  }

  return next()
}

// ------------------------------------------------
