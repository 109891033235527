import { Action, Subject } from '@/types/acl'
import type { RouteRecordRaw } from 'vue-router'

// ------------------------------------------------

export const featureWallRoute: RouteRecordRaw = {
  name: 'domain-monitoring-feature-wall',
  path: '/domain-monitoring/overview',
  component: () => import('./FeatureWallPage.vue'),
  meta: {
    action: Action.VIEW,
    subject: Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL,
  },
}

// ------------------------------------------------
