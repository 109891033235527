import { fetchMyself } from '@/api/user'
import { useAbility } from '@/plugins/casl'
import { TenantAccessType } from '@/types/user'
import { Myself } from '@/types/user/myself'
import { size } from 'lodash-es'
import { defineStore } from 'pinia'

export const useMyselfStore = defineStore('myself', {
  state: () => ({
    myself: undefined as Myself | undefined,
    isLoading: false,
  }),
  getters: {
    isLoggedInAsOrganization: (state) =>
      state.myself?.loggedInTo?.type === TenantAccessType.ORGANIZATION,
    isLoggedInAsPartner: (state) => state.myself?.loggedInTo?.type === TenantAccessType.PARTNER,
    // to delete?
    hasManyTenant: (state) => size(state.myself?.accessTo) > 1,
    // to delete?
    hasNoTenant: (state) => size(state.myself?.accessTo) === 0,
    // to delete?
    isTenantLess: (state) => state.myself?.loggedInTo === null,
    belongToPartner: (state) => state.myself?.belongsTo === TenantAccessType.PARTNER,
    partnerOrganizations: (state) =>
      state.myself?.accessTo.find((accessTo) => accessTo.type === TenantAccessType.PARTNER)
        ?.organizations ?? [],
    isMfaEnrolled: (state) => state.myself?.isMfaEnrolled,
    isSsoEnabled: (state) => state.myself?.isSsoEnabled,
    getTenantRole: (state) => state.myself?.loggedInTo?.permissionId,
    getLoggedInEmail: (state) => state.myself?.email ?? null,
  },
  actions: {
    setMyself(data: Myself) {
      this.myself = data
    },

    patchMyself(data: Partial<Myself>) {
      if (this.myself) {
        Object.assign(this.myself, data)
      }
    },

    async setMyselfFromApi() {
      try {
        this.isLoading = true

        const myself = await fetchMyself()
        this.setMyself(myself)

        const { update } = useAbility()
        update()
      } finally {
        this.isLoading = false
      }
    },
  },
})
