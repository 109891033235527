import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { featureWallRoute } from './FeatureWall/routes'
import { listBreachesRoute } from './ListBreaches/routes'
import { monitoredDomainRepository } from '@/modules/CyberThreatIntelligence/infra/adapters/monitored-domain.repository'

// ------------------------------------------------

export const breachesRoute: RouteRecordRaw = {
  beforeEnter: redirectToModuleOrFeatureWall,
  name: 'breaches',
  path: '/breaches',
  children: [featureWallRoute, listBreachesRoute],
}

// ------------------------------------------------

// @TODO: avoid async in beforeEnter leads to poor UX
async function redirectToModuleOrFeatureWall(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: any,
) {
  if (to.name === featureWallRoute.name) {
    return next()
  }

  const {
    domains: [domain],
  } = await monitoredDomainRepository.findAll()

  if (!domain) {
    return next({ name: featureWallRoute.name })
  }

  if (!to.params.domain) {
    return next({ path: `/breaches/${domain.name}` })
  }

  return next()
}

// ------------------------------------------------
