import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const trainingAwarenessFeatureWallRoute: RouteRecordRaw = {
  path: '/awareness/overview',
  name: 'training-awareness-feature-wall',
  component: () => import('./TrainingAwarenessFeatureWall.vue'),
  meta: {
    action: Action.ACCESS,
    subject: Subject.TRAINING_AWARENESS_FEATURE_WALL,
  },
}
