import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const vishingListScenariosRoute: RouteRecordRaw = {
  path: '/vishing/scenarios',
  name: 'vishing-list-scenarios',
  component: () => import('./ListScenarios.vue'),
  meta: {
    action: Action.ACCESS,
    subject: Subject.VISHING,
  },
}
