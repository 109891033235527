import { createI18n } from 'vue-i18n'
import enMessages from './locales/en.json'

/** @constant {string} DEFAULT_APP_LANGUAGE */
export const DEFAULT_APP_LANGUAGE = 'en'

/** @constant {string[]} AVAILABLE_LANGUAGES */
export const AVAILABLE_APP_LANGUAGES: Set<AvailableLanguage> = new Set(['en', 'fr'])

type AvailableLanguage = 'en' | 'fr'

export const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_APP_LANGUAGE,
  messages: {
    [DEFAULT_APP_LANGUAGE]: enMessages,
  },
  fallbackLocale: DEFAULT_APP_LANGUAGE,
})

export const browserAvailableLanguage = (): AvailableLanguage => {
  const browserLanguage = navigator.language.split('-')[0]

  if (isAvailableLanguage(browserLanguage)) {
    return browserLanguage
  }

  return DEFAULT_APP_LANGUAGE
}

export const isAvailableLanguage = (language: string): language is AvailableLanguage => {
  return AVAILABLE_APP_LANGUAGES.has(language as AvailableLanguage)
}
